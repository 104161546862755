/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import womanLaptopPhone from '../../images/heroes/woman-laptop-phone.jpg'
import manGardening from '../../images/people/gardening.jpg'
import womanMobilePhone from '../../images/people/woman-on-mobile-phone.jpg'

import { StaticImage } from 'gatsby-plugin-image'
import CountdownTimer from '../../components/Countdown'
import DynamicCTACard from '../../components/DynamicCTACard'
import SeoCtaBar from '../../components/SeoCtaBar'

import CobraBanner from '../../components/CobraBanner'

import {
  Accordion,
  Columns,
  Column,
  Hero,
  Link,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../../components/Footer'
import header from '../../components/Header'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi()

  const brandyList = ['disclaimer-aet-eligibility']

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title: 'Find Your Window of Eligibility | Aetna Medicare Eligibility',
          description:
            "Find out if you're eligible for Aetna Medicare plans and get information about  enrollment. Don't miss your window!",
        },
        path: '/resources/eligibility',
        promoCode: '148748',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'aetnamedicare',
    defaultRobots: 'follow,index',
    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero"
              image={
                <img
                  src={womanLaptopPhone}
                  alt="elderly woman looks at laptop while talking on mobile phone"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Medicare Plan Eligibility shouldn't be a mystery
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>
        <SeoCtaBar />
        <CountdownTimer />
        <SplitContent
          alignImageToBottom={false}
          image={
            <img
              src={manGardening}
              alt="an elderly man in a wheelchair gives grandchild a hug"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                When are you eligible for Medicare? Aetna can help you find out
              </Typography>
              <Typography variant="body">
                Medicare eligibility is based on many different factors,
                including your age, disability status, and whether you or your
                spouse qualify to receive Social Security or Railroad Retirement
                Board benefits. If that sounds a little complicated, don’t
                worry. Our friendly, licensed agents can help take the mystery
                out of your individual Medicare eligibility requirements with
                just a simple phone call. Speak to an agent today to find out if
                you qualify for Medicare benefits.
              </Typography>
              <Typography variant="body">
                Want more info before you talk to an agent? No problem! Read on
                to learn more about when you’re eligible to enroll in Medicare
                health and prescription drug insurance plans.
              </Typography>
            </>
          }
        />

        <CobraBanner />

        <VariableContent
          backgroundColor="lightGray"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                What is the Medicare eligibility age?
              </Typography>
              <Typography variant="body">
                Generally speaking, the magic number is 65. In other words, the
                requirements for enrolling in Medicare change depending on
                whether you’re older or younger than 65.
              </Typography>
            </>
          }
        >
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-65-plus.svg"
                alt="65+ icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h3">
                Medicare eligibility at 65 or older
              </Typography>
              <Typography variant="body">
                If you’re at least 65 years old and a citizen of the US who's
                been living in the country for at least five years, chances are
                you’re already eligible to enroll in Medicare Part A and Part B
                (also called Original Medicare). In fact, if you’re already
                receiving Social Security or Railroad Retirement Board benefits,
                you will be automatically enrolled in Original Medicare starting
                the month of your 65th birthday.
              </Typography>
              <Typography variant="body">
                In addition, if you’re eligible for Original Medicare (Medicare{' '}
                <a href="/parta">Parts A</a> and{' '}
                <a href="/medicare-part-b">B</a>), you also have the option to
                enroll in other Medicare plans, such as{' '}
                <a href="/advantage">Medicare Advantage</a>(Part C),
                prescription drug coverage (Part D), or{' '}
                <a href="/plans">Medicare Supplement</a> (Medigap). You can do
                so during any of your eligible Medicare enrollment periods.
                Additional charges might apply if you delay enrollment in a
                prescription drug plan.
              </Typography>
            </Column>
          </Columns>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-65-minus.svg"
                alt="65+ icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h3">
                Medicare eligibility before 65
              </Typography>
              <Typography variant="body">
                Generally speaking, if you’re under the age of 65, you are only
                eligible to receive Medicare benefits if you have certain
                Medicare-recognized disabilities, such as end-stage renal
                disease (otherwise known as kidney failure or ESRD) or
                amyotrophic lateral sclerosis (also called ALS or Lou Gehrig’s
                disease).
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <CobraBanner />

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-checklist.svg"
                alt="checklist icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">Medicare plans eligibility</Typography>
              <Typography variant="body">
                As you might have guessed, each Medicare plan comes with
                different eligibility. Tap or click on the plan you’re
                interested in to learn more.
              </Typography>
              <Stack spacing="xxl" alignMainContent="center">
                <Accordion
                  items={[
                    {
                      title: 'Medicare Part A eligibility',
                      content: (
                        <>
                          <Typography>
                            If you’re an American citizen who’s lived in the US
                            for at least five years, you are eligible to apply
                            for <a href="/parta">Medicare Part A</a> starting
                            three months before the first day of the month of
                            your 65th birthday. Or, if your birthday happens to
                            fall on the first day of the month, you can apply
                            for Medicare up to four months prior to the month of
                            your 65th birthday.
                          </Typography>
                          <Typography>
                            You may even qualify for automatic enrollment
                            (whether or not you’re still working) in Medicare
                            Part A if you meet the following requirements:
                          </Typography>
                          <List>
                            <ListItem>
                              You or your spouse have worked and paid Medicare
                              taxes for at least 10 years before you enroll
                            </ListItem>
                            <ListItem>
                              You or your spouse are eligible to receive Social
                              Security or Railroad Retirement Board (RRB)
                              benefits
                            </ListItem>
                          </List>
                        </>
                      ),
                    },
                    {
                      title: 'Medicare Part B elgibility',
                      content: (
                        <>
                          <Typography>
                            The eligibility requirements for Medicare Part B are
                            the exact same as{' '}
                            <a href="/parta">Medicare Part A</a>, but with one
                            exception. If you aren’t receiving Social Security
                            or Railroad Retirement Board benefits when you turn
                            65 (because you’re still working, for example), you
                            will not be automatically enrolled in Medicare Part
                            B. Instead, you must apply for Medicare Part B
                            coverage yourself during one of your designated
                            Medicare enrollment periods.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'Medicare Advantage (Part C) eligibility',
                      content: (
                        <>
                          <Typography>
                            Unlike Medicare Parts A and B, which are provided by
                            the federal government,{' '}
                            <a href="/advantage">Medicare Part C</a> is only
                            offered through private insurance companies.
                          </Typography>
                          <Typography>
                            You are eligible to enroll in a Medicare Advantage
                            plan if you:
                          </Typography>
                          <List>
                            <ListItem>
                              Have both Medicare Part A and Part B (Original
                              Medicare)
                            </ListItem>
                            <ListItem>
                              Live in a location where Medicare Advantage plans
                              are offered
                            </ListItem>
                          </List>
                          <Typography>
                            To find Medicare Advantage plans near you, talk to a
                            licensed agent today at{' '}
                            <Link to={`tel:${rotatedNumber}`}>
                              {rotatedNumber}
                            </Link>
                            .
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'Medicare Part D (prescription drug) eligibility',
                      content: (
                        <>
                          <Typography>
                            Similar to <a href="/advantage">Medicare Part C</a>{' '}
                            (Medicare Advantage), Medicare Part D plans are
                            offered exclusively by private insurance companies.
                            You are eligible for Medicare Part D coverage if you
                            have Medicare Part A or Part B benefits (or both)
                            and if you live in an area where Part D plans are
                            sold.
                          </Typography>
                          <Typography>
                            To find Medicare prescription drug plans near you,
                            talk to a licensed agent today at{' '}
                            <Link to={`tel:${rotatedNumber}`}>
                              {rotatedNumber}
                            </Link>
                            .
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'Medicare Supplement (Medigap) eligibility',
                      content: (
                        <>
                          <Typography>
                            You are eligible to enroll in a Medicare Supplement
                            plan if you:
                          </Typography>
                          <List>
                            <ListItem>
                              Have <i>both</i> Medicare Part A and Part B
                              (Original Medicare)
                            </ListItem>
                            <ListItem>
                              Live in a location where Medicare Supplement plans
                              are offered
                            </ListItem>
                          </List>
                        </>
                      ),
                    },
                  ]}
                />
              </Stack>
            </Column>
          </Columns>
        </VariableContent>

        <VariableContent
          backgroundColor="lightGray"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                Call the Medicare eligibility phone number
              </Typography>
              <Typography variant="body">
                Finding out whether you’re eligible for Medicare is quick and
                easy. A licensed agent can help you find out in just a matter of
                minutes. Talk to one today!
              </Typography>
              <Link to={`tel:${rotatedNumber}`} className="large">
                Call {rotatedNumber}
              </Link>
            </>
          }
        ></VariableContent>

        <SplitContent
          alignImageToBottom={false}
          image={
            <img
              src={womanMobilePhone}
              alt="an elderly woman talks on mobile phone while holding glasses and smiling"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Enroll in a Medicare Plan Today!
              </Typography>
              <Typography variant="body">
                Ready to Enroll for coverage? Get started by tapping or clicking
                the button below. If you need help during the application
                process, simply contact a friendly licensed agent at{' '}
                <Link to={`tel:${rotatedNumber}`}>{rotatedNumber}</Link>.
              </Typography>
              <div className="card bg-light box-shadow">
                <Typography variant="h4">
                  Speak with a licensed agent{' '}
                </Typography>
                <LinkButton
                  variant="feature"
                  to={`tel:${rotatedNumber}`}
                  color="primary"
                  className="margin-x-auto"
                >
                  Click Here
                </LinkButton>
              </div>
            </>
          }
        />

        <VariableContent
          alignMainContent="center"
          mainContent={
            <Typography variant="h2">Frequently asked questions</Typography>
          }
        >
          <Accordion
            items={[
              {
                title: 'Can I get Medicare at age 62?',
                content: (
                  <>
                    <Typography>
                      Unless you have been diagnosed with a Medicare-covered
                      disability, the short answer is no, you can’t. Typically,
                      you must be at least 65 years old to receive Medicare
                      benefits. However, under some circumstances, your spouse
                      may qualify for Medicare, even if you’re younger than 65
                      and retired.
                    </Typography>
                    <Typography>
                      For example, let’s say you retired at 62 years old, but
                      your spouse just turned 65. Let’s further say your spouse
                      hasn’t worked or paid Medicare taxes for at least 10 years
                      (the minimum to qualify for automatic enrollment in
                      Medicare Part A) prior to turning 65. But you, on the
                      other hand, have been working and paying Medicare taxes
                      for more than 10 years.
                    </Typography>
                    <Typography>
                      In this scenario, you would not be able to enroll in
                      Medicare Part A yet, but your spouse would.
                    </Typography>
                    <Typography>
                      That’s just one example, but if you’d like to learn
                      whether you and your spouse’s circumstances may qualify
                      one or both of you for Medicare, talk to a licensed agent
                      today.
                    </Typography>
                  </>
                ),
              },
              {
                title:
                  'I’ve heard you can get Medicare at age 55. Is that true?',
                content: (
                  <>
                    <Typography>
                      Not exactly. The Medicare at 55 Act is a bill that was
                      introduced in the US Senate back in 2017 and that would
                      allow people aged 55 to 64 to enroll in Medicare. In other
                      words, this bill—if passed—would lower the effective
                      Medicare eligibility age from 65 to 55.
                    </Typography>
                    <Typography>
                      As you might imagine, the Medicare at 55 Act is the
                      subject of much debate. It may be some time before this
                      bill is made into law—<i>if</i> it’s ever made into a law
                      in the first place—and changes to the minimum Medicare
                      eligibility age are made.
                    </Typography>
                  </>
                ),
              },
            ]}
          />
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
